<template>
  <div class="animated fadeIn">
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1 class="mb-5">{{ $t('page.tos2_title') }}</h1>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_5') }}</h4>
            <span v-html="$t('pages.tos.content_5')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_10') }}</h4>
            <span v-html="$t('pages.tos.content_10')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_13') }}</h4>
            <span v-html="$t('pages.tos.content_13')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_15') }}</h4>
            <span v-html="$t('pages.tos.content_15')"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Tos"
    }
</script>

<style scoped>

</style>
